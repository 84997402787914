import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      // 사이드, 탑바 없이 풀 페이지
      component: () => import('@/components/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '/',
          redirect: "/home"
        },
        {
          path: "/home",
          component: () => import('@/components/home/HomePage')
        },
        {
          path: "/login",
          component: () => import('@/components/LoginPage')
        },
        {
          path: "/signup",
          component: () => import('@/components/signup/SignUpPage.vue')
        },
        {
          path: "/product",
          component: () => import('@/components/product/ProductPage.vue')
        },


        // 친구 카드 분기
        {
          path: "/friends/certify/",
          component: () => import('@/components/card/CertifyCard.vue')
        },

        // 친구 카드
        {
          path: "/friends/card1",
          component: () => import('@/components/card/Card.vue')
        },
        {
          path: "/friends/AstellasWebinar03",
          component: () => import('@/components/card/Card.vue')
        },
        // 0330 하루날디 웨비나 초청장
        {
          path: "/friends/AstellasWebinar0330",
          component: () => import('@/components/card/Card.vue')
        },
        //  베타미가 학술대회 (03-04)
        {
          path: "/friends/AstellasBetmigaSympo04",
          component: () => import('@/components/card/Card.vue')
        },
        //  ESLA 행사 연기 안내 (04)
        {
          path: "/friends/AstellasESLA04",
          component: () => import('@/components/card/Card.vue')
        },

        //  프로그랍 Tx 1차 카드뉴스
        {
          path: "/friends/PrografTx_1st",
          component: () => import('@/components/card/Card.vue')
        },

        // 0411 하루날디 웨비나 초청장
        {
          path: "/friends/AstellasWebinar0411",
          component: () => import('@/components/card/Card.vue')
        },
        //  2024 대한비뇨의학회 학술행사 (04-07)
        {
          path: "/friends/HarnaldSympo04",
          component: () => import('@/components/card/Card.vue')
        },

        //  2024 KCR (05)
        {
          path: "/friends/PrografAi_KCR2024",
          component: () => import('@/components/card/Card.vue')
        },

        //   Urology Webinar (05)
        {
          path: "/friends/HarnaldUrology05",
          component: () => import('@/components/card/Card.vue')
        },

        // 프로그랍 Tx 2차 카드뉴스 (신이식)
        {
          path: "/friends/PrografTx_2nd_kidney",
          component: () => import('@/components/card/Card.vue')
        },


        // 프로그랍 Tx 2차 카드뉴스 (간이식)
        {
          path: "/friends/PrografTx_2nd_liver",
          component: () => import('@/components/card/Card.vue')
        },

        // 프로그랍 Ai 동행 카드뉴스 (0503)
        {
          path: "/friends/PrografAi_DH",
          component: () => import('@/components/card/Card.vue')
        },

        // Harnald Card News (05) (채널톡 발송용)
        {
          path: "/friends/Harnald_News05",
          component: () => import('@/components/card/Card.vue')
        },

        // 2024 대한비뇨의학회 학술행사 (05-08) (채널톡 발송용)
        {
          path: "/friends/HarnaldSympo05",
          component: () => import('@/components/card/Card.vue')
        },

        // 프로그랍 Ai 동행 카드뉴스 (Tacrolimus) (채널톡 발송용)
        {
          path: "/friends/PrografAi_DH_Tacrolimus",
          component: () => import('@/components/card/Card.vue')
        },

        // 0613 하루날디 웨비나 초청장 (채널톡 발송용)
        {
          path: "/friends/AstellasWebinar0613",
          component: () => import('@/components/card/Card.vue')
        },

        // 프로그랍 Tx 3차 카드뉴스 (채널톡 발송용)
        {
          path: "/friends/PrografTx_3rd",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf (AI) 동행 캠페인 TAC concert (채널톡 발송용)
        {
          path: "/friends/PrografAi_DH_TAC_Connect",
          component: () => import('@/components/card/Card.vue')
        },

        // 프로그랍 Tx 힐링캠프 웨비나 초청장 (채널톡 발송용)
        {
          path: "/friends/PrografTx_HealingCamp",
          component: () => import('@/components/card/Card.vue')
        },


        // 24년 6월 베타미가 카드뉴스 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmiga06",
          component: () => import('@/components/card/Card.vue')
        },

        // 0716 하루날디 웨비나 초청장 (채널톡 발송용)
        {
          path: "/friends/AstellasWebinar0716",
          component: () => import('@/components/card/Card.vue')
        },

        // 1129 하루날디 웨비나 초청장 (채널톡 발송용)
        {
          path: "/friends/AstellasWebinar1029",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf Ai_Japanese PMS data (채널톡 발송용)
        {
          path: "/friends/PrografAi_Japanese_PMS",
          component: () => import('@/components/card/Card.vue')
        },

        // Harnald Card News 07월 (채널톡 발송용)
        {
          path: "/friends/Harnald_News07",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf Ai_Long-Term Safety Data (채널톡 발송용)
        {
          path: "/friends/PrografAi_LongTermCard",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf TX_Transplant Expert Summit (채널톡 발송용)
        {
          path: "/friends/PrografTx_TransplantExpertSummit",
          component: () => import('@/components/card/Card.vue')
        },
        // 아스텔라스 Prograf TX_Transplant Lucky For NewLife (채널톡 발송용)
        {
          path: "/friends/PrografTx_TransplantLuckyForNewLife",
          component: () => import('@/components/card/Card.vue')
        },
        // 아스텔라스 Prograf TX_Transplant Industry Symposium (채널톡 발송용)
        {
          path: "/friends/PrografTx_TransplantIndustrySymposium",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf AI_TJC Improvement (채널톡 발송용)
        {
          path: "/friends/PrografAi_TJC_Improvement",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf AI_ESLA Symposium (채널톡 발송용)
        {
          path: "/friends/PrografAi_ESLASymposium",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 7월 베타미가 카드뉴스 _ 웨비나 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmiga07",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 8월 베타미가 카드뉴스 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmiga08",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 8월 베타미가 카드뉴스 _ 학회일정 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmigaSympo08",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 8월 아스텔라스 Prograf TX_TES2024 (채널톡 발송용)
        {
          path: "/friends/PrografTx_TES2024",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 8월 아스텔라스 하루날디 베타미가 웨비나 09월 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmiga09Webinar",
          component: () => import('@/components/card/Card.vue')
        },

        // 아스텔라스 Prograf Ai_Long-Term Safety Data (채널톡 발송용)
        {
          path: "/friends/PrografAi_LongTermTrustCard",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 9월 베타미가 카드뉴스 _ 학회일정 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmigaSympo09",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 09월 아스텔라스 Prograf Ai_Long-Term TRUST #2-1 Safety Data (채널톡 발송용)
        {
          path: "/friends/PrografAi_LongTermTrust201Card",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 10월 아스텔라스 Prograf Ai_Ten Years TRUST (채널톡 발송용)
        {
          path: "/friends/PrografAi_TenYearsTrustCard",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 09월 아스텔라스 Prograf (AI) 25th TAC concert (채널톡 발송용)
        {
          path: "/friends/PrografAi_25th_TAC_ConcertCard",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 10월 아스텔라스 Prograf (AI) ESLA Symposium (채널톡 발송용)
        {
          path: "/friends/PrografAi_ESLA_Sympo10Card",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 09월 프로그랍 Tx 힐링캠프 웨비나 초청장 (채널톡 발송용)
        {
          path: "/friends/PrografTx_2409HealingCampCard",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 9월 베타미가 카드뉴스 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmiga09",
          component: () => import('@/components/card/Card.vue')
        },

        // 24년 10월 베타미가 카드뉴스 (채널톡 발송용)
        {
          path: "/friends/AstellasBetmiga10",
          component: () => import('@/components/card/Card.vue')
        },

        // 베타미가 학술대회 (08-12) 퀵메뉴
        {
          path: "/friends/AstellasBetmigaSympo09Temp",
          component: () => import('@/components/card/Card.vue')
        },
        // 베타미가 하루날디 11월
        {
          path: "/friends/AstellasBetmigaSympo10",
          component: () => import('@/components/card/Card.vue')
        },

        /** 
         * 퀵메뉴 인증 분기
         * 리다이렉션 지점
         *  */
        {
          path: "/quickMenu/certify/",
          component: () => import('@/components/card/CertifyContents.vue')
        },
        // {
        //   path: "/card",
        //   component: () => import('@/components/card/Card.vue')
        // },
        // {
        //   path: "/card/harnalD",
        //   component: () => import('@/components/card/Card.vue')
        // },
        // {
        //   path: "/card/betamiga",
        //   component: () => import('@/components/card/Card.vue')
        // },
        // {
        //   path: "/card/prografAI",
        //   component: () => import('@/components/card/Card.vue')
        // },

        //  프로그랍 AI RA LA
        {
          path: "/quickMenu/prografAI_RA",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },
        {
          path: "/quickMenu/prografAI_LN",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        //  프로그랍 Tx 뉴스 클리핑 04월
        {
          path: "/quickMenu/PrografTx_NewsClipping_April",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        // 프로그랍 TX 퀵메뉴
        {
          path: "/quickMenu/prografTX",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        // 베타미가 학술대회 (08-12) 퀵메뉴
        {
          path: "/quickMenu/AstellasBetmigaSympo08",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        // 베타미가 학술대회 (08-12) 퀵메뉴
        {
          path: "/quickMenu/AstellasBetmigaSympo09",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        {
          path: "/quickMenu/AstellasBetmigaSympo09Temp",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        // 베타미가 컨텐츠 (24년 08월) 퀵메뉴
        {
          path: "/quickMenu/Betamiga2408",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        // 하루날디 컨텐츠 (24년 08월) 퀵메뉴
        {
          path: "/quickMenu/HanalD2408",
          component: () => import('@/components/card/QuickMenuContents.vue')
        },

        {
          path: "/testComp",
          component: () => import('@/components/testComp/TestComp.vue')
        },
        {
          path: "/ToBeUpdated",
          component: () => import('@/components/ToBeUpdatedPage.vue')
        },
      ]
    },
    {
      path: '/',
      // 사이드, 텝바가 있는 페이지
      component: () => import('@/components/layouts/vertical/LayoutVertical'),
      children: [
        {
          path: "/testComp",
          component: () => import('@/components/testComp/TestComp.vue'),
          meta: { isRequiredAuth: false }
        },
        {
          path: '*',
          component: () => import('@/components/NotFoundPage'),
          meta: { isRequiredAuth: true }
        }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // console.log('isAuthorized', to.meta.isRequiredAuth, store.getters.isAuthorized)
  if (to.meta.isRequiredAuth && !store.getters.isAuthorized) {
    console.log('인증이 필요합니다');
    next('/login');
    return;
  }
  next();
});

export default router;
