<template>
  <div class="footer">
    <h5>※ 한국아스텔라스제약</h5>
    <p>
      주소: 서울시 강남구 테헤란로 521 파르나스타워 7층<br />
      대표 번호: 02-3448-0504 <br />
      업무 시간: 월요일~금요일: 오전 9:00~오후 6:00(토요일과 일요일, 법정 공휴일과 회사 창립 기념일 휴무)<br />
      <!-- {{ vendor }}<br />
      {{ userAgent }}<br /> -->
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vendor: '',
      userAgent: '',
    };
  },
  mounted() {
    this.vendor = navigator.vendor;
    this.userAgent = navigator.userAgent;
  },
};
</script>

<style lang="scss">
.footer {
  background: #303030;
  color: #ffffff;
  padding: 20px 20px 15px;
  h5 {
    color: #ffffff;
  }
  p {
    font-size: 10px;
  }
}
</style>