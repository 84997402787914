<template>
  <div class="app" v-if="visible">
    <!-- antD 컴퍼넌트 한글화 처리 -->
    <a-config-provider :locale="ko_KR">
      <router-view :key="$route.fullPath"></router-view>
    </a-config-provider>
    <Footer />
  </div>
</template>

<script>
import ko_KR from 'ant-design-vue/lib/locale-provider/ko_KR';
import moment from 'moment';
import 'moment/locale/ko';
import Footer from '@/components/Footer.vue';
moment.locale('ko'); // antD 한글화 처리
export default {
  components: {
    Footer,
  },
  data() {
    return {
      ko_KR,
      isDev: process.env.NODE_ENV === 'development',
      visible: true,
    };
  },
  mounted() {
    const userAgent = navigator.userAgent;
    if (!this.isDev) {
      if (userAgent.indexOf('KAKAOTALK') < 0) {
        this.visible = false;
        alert('허용되지 않는 접근입니다');
        console.log('잘못접근');
        return;
      }
    }
  },
};
</script>

<style lang="scss">
@import 'assets/css/common.css';
@import 'assets/scss/layout';
@import 'assets/scss/style';
@import 'assets/scss/common';
</style>
